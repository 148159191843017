body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.posts-container {
  font-family: 'Roboto', sans-serif;
  background-color: #f4e7d2;
  color: #2b2b2b;
  padding: 2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid #d7a373;
  border-radius: 8px;
  margin: 1rem;
}

.thumbnail {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 8px;
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.posts-title {
  font-size: 24px;
  font-weight: bold;
  color: #bf7c17;
  margin-bottom: 10px;
}

.subheading {
  font-size: 18px;
  font-weight: bold;
  color: #bf7c17;
  margin-bottom: 10px;
}

.text {
  font-size: 16px;
  color: #777;
  line-height: 1.5;
}

/* Global styles */
.header {
  margin-top: 60px;
}

.headerImg {
  width: 100%;
  height: 750px;
  margin-top: 80px;
  object-fit: cover;
}

.headerTitles {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: teal;
}

.headerTitlesSm {
  position: absolute;
  top: 18%;
  font-size: 33px;
}

.headerTitlesLg {
  position: absolute;
  top: 20%;
  font-size: 100px;
}
