/* General Styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Bebas Neue', sans-serif;
  background-color: #1a1a1a;
  color: #fff;
}

.section {
  margin-bottom: 40px;
}

.section-header {
  font-size: 2em;
  font-weight: bold;
  color: #ffcc00;
  background-color: #333;
  padding: 5px 10px;
  border-radius: 5px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Responsive Typography */
@media screen and (min-width: 768px) {
  .section-header {
    font-size: 2.5em; /* Adjust font size for larger screens */
  }
}

/* About Section */
.about-container {
  text-align: center;
  padding: 20px;
}

.about-me-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-me-text {
  width: 60%;
  text-align: left;
}

.image-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.profile-image,
.stationary-image {
  width: 200px;
  height: 200px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin: 20px;
}

.profile-image {
  background-image: url('../public/images/frog.jpeg');
  animation: rotate 5s linear infinite;
  display: flex;
  animation-direction: alternate;
  z-index: 2;
}

.stationary-image {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url('../public/images/image1.jpg');
  z-index: 1;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Mobile responsiveness... */
@media (max-width: 768px) {
  .profile-image,
  .stationary-image {
    width: 150px;
    height: 150px;
  }
}

/* TopBar */
.TopBar {
  width: 100%;
  height: auto;
  background-color: #17151c;
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.TopLeft,
.TopRight,
.TopCenter {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TopImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 2px 2px 2px 2px #666;
  background-color: #8d918d;
}

.TopIcon {
  font-size: 20px;
  margin-right: 10px;
  color: #8d918d;
  cursor: pointer;
}

.TopList {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  justify-content: center;
}

.topListItem {
  margin: 10px;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
  color: #2b2b2b;
}

.TopSearchIcon {
  font-size: 18px;
  color: #8d918d;
  cursor: pointer;
  margin-top: 10px;
}

/* Podcast Page */
.podcast-page {
  text-align: center;
  padding: 20px;
  background-color: #17151c;
  color: #fff;
}

.episode-container {
  max-width: 800px;
  margin: 20px auto;
}

.posts-title {
  font-size: 2rem;
  font-weight: bold;
  color: #ffcc00;
  background-color: #333;
  padding: 5px 10px;
  border-radius: 5px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.episode-box {
  background-color: #333;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.subheading {
  font-size: 1.5rem;
  font-weight: bold;
  color: #bf7c17;
  margin-bottom: 10px;
}

.text {
  font-size: 1.25rem;
  color: #ccc;
  line-height: 1.5;
}

/* Responsive styles for episodes */
@media (max-width: 768px) {
  .episode-box {
    padding: 15px;
  }
}

/* News Feed Section */
.news-feed-section {
  background-color: #333;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin-top: 20px;
  text-align: center;
}

.news-list {
  list-style: none;
  padding: 0;
}

.news-item {
  margin: 10px 0;
  font-size: 1.2rem;
  color: #fff;
}

.news-title {
  color: gold;
}

/* Social Media Section */
.social-media-section {
  background-color: #333;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 
}
.btc-hoodie{
  min-width: 10vh;
  min-height: 10vh;
  background-image: url('https://707600-4.myshopify.com/cdn/shop/files/6591690103944600904_2048.jpg?v=1701444350&width=1206');
}
.shop-container {
  text-align: center;
  padding: 20px;
}

.shop-link {
  text-decoration: none;
  color: #fff;
}

.shop-link:hover {
  color: teal;
}

.btc-hoodie {
  width: 100%;
  height: 300px; /* Adjust the height as needed */
  background-image: url('https://707600-4.myshopify.com/cdn/shop/files/6591690103944600904_2048.jpg?v=1701444350&width=1206');
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.scouse-jumper {
  width: 100%;
  height: 300px; /* Adjust the height as needed */
  background-image: url('https://707600-4.myshopify.com/cdn/shop/files/5271224077752847905_2048.jpg?v=1701946979&width=823');
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* HeroSection styles */
.hero-section {
  text-align: center;
  padding: 50px 0;
}

.hero-title {
  font-size: 3em;
  font-weight: bold;
  color: #ffcc00;
  margin-bottom: 20px;
}

.hero-description {
  font-size: 1.2em;
  line-height: 1.6;
}

/* Testimonials styles */
.testimonials-container {
  text-align: center;
  padding: 50px 0;
}

.testimonials-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.testimonial-item {
  background-color: #333;
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width: 300px;
}

.testimonial-text {
  font-size: 1.2em;
  color: #ccc;
  line-height: 1.6;
}

.author-name,
.author-position {
  color: #bf7c17;
  margin: 10px 0;
  font-weight: bold;
}

/* Responsive styles */
@media (max-width: 768px) {
  .testimonial-item {
    width: 100%;
  }
}