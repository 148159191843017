.hero-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  background-color: #2b2b2b;
}

.tag-link {
  text-decoration: none;
  color: #17151c;
  text-align: center;
  flex: 1 1 200px; /* Adjust as needed */
  margin: 10px;
}

.tag-image {
  width: 100%; /* Adjust the size as needed */
  max-width: 150px; /* Set a maximum width */
  height: auto;
  border-radius: 33px;
  box-shadow: #2b2b2b 1px 1px 1px;
  margin-bottom: 10px;
}

.tag-label {
  display: block;
  font-size: 14px;
  font-weight: bold;
}

/* Responsive styles */
@media (max-width: 768px) {
  .tag-link {
    flex-basis: 100%; /* Make the tags full width on smaller screens */
  }
}
