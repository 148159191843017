/* chapter.css */

.chapter-box {
  max-width: 800px;
  margin: 20px auto;
  background-color: #333;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

h1 {
  color: #ffcc00;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  font-size: 32px;
  margin-bottom: 20px;
}

p {
  line-height: 2.6;
  margin-bottom: 19px;
  font-size: 18px;
  background-color: #1a1a1a;
  opacity: 90%;
}

a {
  color: gold;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s ease;
}

a:hover {
  border-bottom: 2px solid teal;
}

.chapter-button {
  margin-top: 20px;
  text-align: center;
}

.chapter-button button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #bf7c17;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chapter-button button:hover {
  background-color: #8d5817;
}

.chapter-background {
  background: url(../../public/images/frog.jpeg);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: transparent;
  color: #ffd700; /* Gold color */
  border: 2px solid #008080; /* Teal color */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

button:hover {
  background-color: #008080; /* Teal color on hover */
  color: #fff;
}